import React, { useState } from 'react';
import { string } from 'prop-types';
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  Select,
  Text,
  useToast,
  Heading,
  useDisclosure,
  Flex,
} from '@chakra-ui/react';

import WithAnimation from '@components/Common/WithAnimation';
import LazyImage from '@components/Common/LazyImage';
import GuestInformation from './GuestInformation';

import useMutation from '@/hooks/useMutation';
import useGuestInfo from '@/hooks/useGuestInfo';
import usePartner from '@hooks/usePartner';
import useFamily from '@hooks/useFamily';

import { API_HOSTNAME, API_RSVP_TABLE } from '@/constants';
import { BUTTON_PROPS } from '@/constants/colors';
import { ASSETS_FLOWER_LEFT } from '@/constants/assets';

import {
  FORM_LABEL_PROPS,
  INPUT_COMMON_PROPS,
  SELECT_COMMON_PROPS,
  TYPE,
  ERROR_TYPE,
  ERROR_PROPS,
} from './types';
import { ENABLE_GUEST_INFORMATION, ENABLE_GUEST_PERSONALIZATION, ENABLE_PARTNER_MODE } from '@/constants/feature-flags';

import { txtForm as txt } from './locales';

const enc = (string) => encodeURIComponent(string);

/**
 * function to render RSVP component
 * @returns {JSX.Element}
 * @author idindrakusuma
 */
function RSVPSection({ lang, ...rest }) {
  const toast = useToast();
  const { name: guestName = '' } = useGuestInfo();
  const finalName = guestName.toLowerCase() === 'guest' ? '' : guestName;
  const { isOpen, onClose, onOpen } = useDisclosure();

  const [onSentConfirmation, loading] = useMutation(API_HOSTNAME, API_RSVP_TABLE, 'insert');
  const partner = usePartner();
  const isFamily = useFamily();

  const [name, setName] = useState(() => finalName);
  const [phone, setPhone] = useState('');
  const [address, setAddress] = useState('-');
  const [attended, setAttended] = useState(TYPE.YES);
  const [bringPartner, setBringPartner] = useState(1);
  const [errorType, setErrorType] = useState(ERROR_TYPE);
  const bringPartnerInt = parseInt(bringPartner, 0);

  /**
   * function to set state
   * @param {object} e - html event value
   * @param {function} setState
   * @param {string} typeState
   * @returns
   */
  const onSetState = (e, setState, typeState) => {
    const value = e.target.value;
    setState(value);

    if (typeState === 'email') {
      if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value)) {
        setErrorType({ ...errorType, email: 'Invalid email address' });
        return;
      }
    }

    if (typeState === 'phone') {
      if (!/\d+/.test(value) || value.length > 13) {
        setErrorType({ ...errorType, phone: 'Invalid phone number' });
        return;
      }
    }

    setErrorType(ERROR_TYPE);
  };

  /**
   * function to submit to BE with check the form value first
   */
  const onSubmitForm = async () => {
    if (errorType.name || errorType.phone || errorType.address) return;

    if (!name || !phone || !address) {
      setErrorType({
        name: !name ? txt.required[lang] : '',
        phone: !phone ? txt.required[lang] : '',
        address: !address ? txt.required[lang] : '',
      });

      return;
    }

    const query =
      `nama=${enc(name)}` +
      `&hp=${phone}` +
      `&hadir=${enc(attended)}` +
      `&jumlah_tamu=${attended === TYPE.NO ? '' : bringPartner}` +
      `&alamat=${enc(address)}`;

    const result = await onSentConfirmation(query);

    if (result.success) {
      toast({
        title: txt.success[lang],
        description: txt.msgSuccess[lang],
        status: 'success',
        duration: 5000,
        isClosable: true,
      });

      setName('');
      setPhone('');
      setAddress('-');
    } else {
      toast({
        title: 'Oops!',
        description: txt.msgError[lang],
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Box bgColor="bgPrimary" padding="24px 0 62px 0" {...rest}>
      <Box width="100%" maxW="calc(500px)" position="absolute">
        <LazyImage
          src={ASSETS_FLOWER_LEFT}
          height="200px"
          position="absolute"
          left="30px"
          zIndex={1}
        />
      </Box>
      <Box padding="0 16px">
        <Box marginTop="42px" padding="24px 20px" width="100%" zIndex={2} position="relative">
          <Box padding="8px" bgColor="bgSecondary" borderRadius="16px">
            <WithAnimation>
              <Box padding="24px 16px" width="100%">
                {/* Title & Desc Section */}
                <Box textAlign="center">
                  <Heading fontWeight="normal" color="bgPrimary" size="3xl">
                    {txt.title[lang]}
                  </Heading>
                  <Text
                    color="bgPrimary"
                    fontSize="sm"
                    margin="16px 0 24px 0"
                    padding="0 12px 0 12px"
                    dangerouslySetInnerHTML={{ __html: txt.desc[lang] }}
                  />
                </Box>

                {/* Form Sections - Name */}
                <FormControl margin="8px 0" isInvalid={errorType.name}>
                  <FormLabel {...FORM_LABEL_PROPS}>{txt.name[lang]}:</FormLabel>
                  <Input
                    {...INPUT_COMMON_PROPS}
                    placeholder="..."
                    value={name}
                    onChange={(e) => onSetState(e, setName)}
                  />
                  <FormErrorMessage {...ERROR_PROPS}>{`* ${errorType.name}`}</FormErrorMessage>
                </FormControl>
                {/* Form Sections - Phone Number */}
                <FormControl margin="16px 0" isInvalid={errorType.phone}>
                  <FormLabel {...FORM_LABEL_PROPS}>{txt.hp[lang]}:</FormLabel>
                  <Input
                    {...INPUT_COMMON_PROPS}
                    placeholder="..."
                    value={phone}
                    onChange={(e) => onSetState(e, setPhone, 'phone')}
                  />
                  <FormErrorMessage {...ERROR_PROPS}>{'* ' + errorType.phone}</FormErrorMessage>
                </FormControl>
                {/* Form Sections - Address */}
                {false && (
                  <FormControl margin="8px 0" isInvalid={errorType.address}>
                    <Input
                      {...INPUT_COMMON_PROPS}
                      height="40px"
                      placeholder={txt.address[lang]}
                      value={address}
                      onChange={(e) => onSetState(e, setAddress)}
                    />
                    <FormErrorMessage {...ERROR_PROPS}>{'* ' + errorType.address}</FormErrorMessage>
                  </FormControl>
                )}
                {/* Form Sections - Attendance */}
                <FormControl margin="8px 0">
                  <FormLabel {...FORM_LABEL_PROPS}>{txt.willYoutAttend[lang]}</FormLabel>
                  <Select
                    {...SELECT_COMMON_PROPS}
                    value={attended}
                    onChange={(e) => onSetState(e, setAttended)}
                  >
                    <option value={TYPE.YES} color="white">
                      {txt.willAttend[lang]}
                    </option>
                    <option value={TYPE.NO}>{txt.noAttend[lang]}</option>
                  </Select>
                </FormControl>
                {/* Form Sections - Partner */}
                {attended === TYPE.YES && ENABLE_PARTNER_MODE && (
                  <>
                    <FormControl>
                      <FormLabel {...FORM_LABEL_PROPS}>{txt.willYouBringPartner[lang]}</FormLabel>
                      <Select
                        {...SELECT_COMMON_PROPS}
                        value={bringPartner}
                        onChange={(e) => onSetState(e, setBringPartner)}
                      >
                        {ENABLE_GUEST_PERSONALIZATION
                          ?
                            <>
                              {!partner && (
                                <option value={1} style={{color: 'black'}}>1</option>
                              )}
                              {partner === 1 && (
                                <option value={1} style={{color: 'black'}}>1</option>
                              )}
                              {partner === 2 && (
                                <>
                                  <option value={1} style={{color: 'black'}}>1</option>
                                  <option value={2} style={{color: 'black'}}>2</option>
                                </>
                              )}
                              {partner === 3 && (
                                <>
                                  <option value={1} style={{color: 'black'}}>1</option>
                                  <option value={2} style={{color: 'black'}}>2</option>
                                  <option value={3} style={{color: 'black'}}>3</option>
                                </>
                              )}
                              {partner === 4 && (
                                <>
                                  <option value={1} style={{color: 'black'}}>1</option>
                                  <option value={2} style={{color: 'black'}}>2</option>
                                  <option value={3} style={{color: 'black'}}>3</option>
                                  <option value={4} style={{color: 'black'}}>4</option>
                                </>
                              )}
                              {partner > 4 && (
                                <>
                                  <option value={1} style={{color: 'black'}}>1</option>
                                  <option value={2} style={{color: 'black'}}>2</option>
                                  <option value={3} style={{color: 'black'}}>3</option>
                                  <option value={4} style={{color: 'black'}}>4</option>
                                  <option value={5} style={{color: 'black'}}>5</option>
                                </>
                              )}
                            </>
                          :
                            <>
                              <option value={1} style={{color: 'black'}}>1</option>
                              <option value={2} style={{color: 'black'}}>2</option>
                            </>
                        }
                      </Select>
                      {/* SET to TRUE if you want to add some information if user bring-partner YES */}
                      {bringPartnerInt > TYPE.YES && false && (
                        <FormHelperText color="mainColorText" fontSize="10px" fontStyle="italic">
                          {txt.singleGuestInfo[lang]}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </>
                )}

                <Flex justifyContent="end">
                  {ENABLE_GUEST_INFORMATION && <Button
                    bgColor="bgPrimary"
                    marginTop="24px"
                    size="sm"
                    type="button"
                    fontFamily="body"
                    fontWeight="normal"
                    fontSize="xs"
                    color="mainColorText"
                    onClick={onOpen}
                    borderRadius="8px"
                    marginRight="8px"
                    {...BUTTON_PROPS}
                  >
                    {isFamily ? 'FAMILY INFORMATION' : 'GUEST INFORMATION'}
                  </Button>}
                  <Button
                    bgColor="bgAlternative"
                    isLoading={loading}
                    marginTop="24px"
                    size="sm"
                    type="button"
                    fontFamily="body"
                    fontWeight="normal"
                    color="white"
                    borderRadius="8px"
                    letterSpacing="2px"
                    textTransform="uppercase"
                    onClick={onSubmitForm}
                    {...BUTTON_PROPS}
                  >
                    {txt.submit[lang]}
                  </Button>
                </Flex>
              </Box>
            </WithAnimation>
          </Box>
        </Box>
        <GuestInformation visible={isOpen} onClose={onClose} lang={lang} />
      </Box>
      {/* Images Bottom */}
      <Box width="100%" maxW="500px" position="absolute">
        <LazyImage
          src={ASSETS_FLOWER_LEFT}
          height="200px"
          position="absolute"
          right="30px"
          transform="rotate(180deg)"
          marginTop="-150px"
          zIndex={1}
        />
      </Box>
    </Box>
  );
}

RSVPSection.propTypes = {
  lang: string,
};

RSVPSection.defaultProps = {
  lang: 'id',
};

export default React.memo(RSVPSection);